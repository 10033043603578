import { render, staticRenderFns } from "./DetailForm.vue?vue&type=template&id=0e6a0250&scoped=true&"
import script from "./DetailForm.vue?vue&type=script&lang=js&"
export * from "./DetailForm.vue?vue&type=script&lang=js&"
import style0 from "./DetailForm.vue?vue&type=style&index=0&id=0e6a0250&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e6a0250",
  null
  
)

export default component.exports